import { Box, BoxProps, Icon, Table, Tbody, Thead, Tr } from '@chakra-ui/react';
import { Td, TdActions, Th } from 'components/lib';
import { getAccountNumber, getBankName, getId, getStatement } from 'helpers';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdFileDownload } from 'react-icons/md';
import { IBankStatementResource } from 'services/resources/financial/bank-statements/types.d';

export interface IBankStatementsProps extends BoxProps {
  /**
   * The bank statements related to a user.
   */
  statements?: IBankStatementResource[];
}

export const BankStatements: React.FC<IBankStatementsProps> = (
  props,
): JSX.Element | null => {
  const { statements, ...rest } = props;

  const { t } = useTranslation('kyc-physical');

  const handleOnDownload = useCallback(async (row: IBankStatementResource) => {
    // window.open(getStatement(row));
    const fileUrl = getStatement(row);
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    const contentType =
      response.headers.get('content-type') || 'application/pdf';
    const extension = contentType.split('/')[1] || 'pdf';
    const fileName = `downloaded-file.${extension}`;

    const tempUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = tempUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(tempUrl);
  }, []);

  if (fp.isEmpty(statements)) {
    return null;
  }

  return (
    <Box {...rest}>
      <Table colorScheme="blackAlpha" variant="striped">
        <Thead>
          <Tr>
            <Th>{t('page.detail.table.title.id')}</Th>
            <Th>{t('page.detail.table.title.bank')}</Th>
            <Th>{t('page.detail.table.title.account-number')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {statements?.map((row: IBankStatementResource) => (
            <Tr key={nanoid()}>
              <Td>{getId(row)}</Td>
              <Td>{getBankName(row)}</Td>
              <Td>{getAccountNumber(row)}</Td>
              <TdActions
                customButtonBg="orange"
                customButtonIcon={<Icon as={MdFileDownload} color="white" />}
                isActive
                onCustomCallback={() => handleOnDownload(row)}
                onlyCustomAction
              />
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

BankStatements.defaultProps = {
  overflow: 'scroll',
};
